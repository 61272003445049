import Core from '_abstracts/core'

export default class SalesForce {
    name = 'SalesForce'

    constructor() {
    }

    async process() {
        try {
            await this.sendToCollect()

        } catch (ex) {
            console.error(ex)
            console.warn('SalesForce.process = Error')
        }
    }

    async sendToCollect() {
        try {
            // tracker sales force mkt cloud...
            window._etmc = window._etmc || []
            window._etmc.push(["setOrgId", "100026685"])                         
        
            var emailToSF = Core.User.get('ticketId')
            var emails = Core.User.get('Emails')            
            const user = Core.User.toJSON()

            if (Core.User.get('isLogged')) {                                
                if (typeof user.Emails !== 'undefined' && user.Emails.length > 0) {               
                    for (var k = 0; k < emails.length; k++) {                      
                        if (Object.prototype.hasOwnProperty.call(emails[k], 'email')
                            && Object.prototype.hasOwnProperty.call(emails[k], 'main')) {
                            // email principal
                            if (emails[k].main) {
                                //console.info("emails[k].email = main = " + JSON.stringify(emails[k].email))            
                                emailToSF = emails[k].email
                            }
                        }
                    }
                }
                // identified user...
                window._etmc.push(["setUserInfo", { 'email': emailToSF, 'user_id': Core.User.get('ticketId') }])
            }
            else  {
                // anonymous user...
                window._etmc.push(["setUserInfo", {'user_id': Core.User.get('ticketId') }])
            }                      
            // trackear para salesforce mkt cloud...
            window._etmc.push(["trackPageView"])

        } catch (ex) {
            console.error(ex)
            console.warn('SalesForce.sendToCollect = Error')
        }
    }

}
