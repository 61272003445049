//  import Backbone from 'backbone'
import _ from 'underscore'
import Core from '_abstracts/core'
// import $ from 'jquery'
import moment from 'moment'
import Event from './models/event'
import EventsClient from '_default/scripts/_back/apis/events'

export default class Tracker {
    /**
     * @property {Event}  event - Model do Evento principal
     */
    Event

    /**
     * @property {String}  name - Nome do Módulo para injeção no Core
     */
    name = 'Tracker'

    /**
     * @property {String} parentIdGlobal - ParendId global para rolagem infinita
     */
    parentIdGlobal = false

    /**
     * @property {Number} readTime - Tempo de leitura da matéria
     */
    readTime = 10000

    constructor () {
        // Define o status do tracker como 'started'
        Core.Status.update('tracker', 'status', 'started')

        // Registra o tracker no Core
        Core.Modules.set(this)

        // Instancia a model Event
        this.Event = new Event()

        // ParendId global para rolagem infinita
        this.parentIdGlobal = false

        // Define o tempo de leitura
        this._setReadTime()

        // Envia o envento de HIT
        this.sendHit()

        // Envia um hit toda vez que trocar de conteúdo
        // Rolagem infinita
        Core.Tasks.add(Core.Events.CHANGE_CONTENT, () => {
            Core.Tasks.add(['tracker'], () => {
                this.sendHit()
            })
        })
    }

    /**
     * Envia um evento de HIT de acordo com o conteúdo atual
     */
    async sendHit () {
        try {
            console.info('Events', 'HIT', Core.Content.get('article'))

            // Limpa o Delay
            clearTimeout(this._delay)

            // Seta os dados do evento principal
            this.setEvent()

            const event = new Event(this.Event.toJSON())

            // Seta o Evento eventType como hit
            event.set('eventType', 'hit')

            // Se existir um parentIdGlobal o atribui
            // implementação para rolagem infinita
            if(this.parentIdGlobal) {
                event.set('parentId', this.parentIdGlobal) 
            }

            // Envia o Evento para a API
            const res = await EventsClient.register(event.toJSON())

            // Atualiza os dados do Event principal
            this.Event.set('ticketId', res.ticketId)
            this.Event.set('sessionId', (res.sessionId) ? res.sessionId : '')
            this.Event.set('parentId', res.id)

            // Se não existir um parentId Global o define
            // implementação para rolagem infinita
            if(!this.parentIdGlobal) {
                this.parentIdGlobal = res.id
            }

            // Atualiza os dados do usuário
            Core.User.set('ticketId', this.ticketId)
            Core.User.set('sessionId', (res.sessionId) ? res.sessionId : '')

            // Atualiza os dados do cookie do usuário
            Core.User.Cookies.updateTrackerCookies(res)

            // Finaliza o status do Tracker
            Core.Status.update('tracker', 'status', 'finished')

            // Se a página não for 404 envia o READ
            if (this.Event.get('eventNotes') !== 'error:404') {
                this._delay = _.delay(() => {
                    this.sendReadEvent = true
                    this.sendRead()
                }, this.readTime)
            }
        } catch (ex) {
            Core.Status.update('tracker', 'status', 'finished')
            console.error(ex)
        }
    }

    /**
     * Envia um evento de READ de acordo com o conteúdo atual
     */
    async sendRead () {
        try {
            Core.Tasks.add(['paywall'], async () => {
                if (Core.Status.get('paywall', 'status') === 'finished') {
                    console.info('Events', 'READ', Core.Content.get('article'))

                    // Faz um clone do evento principal
                    const event = new Event(this.Event.toJSON())
    
                    // Altera o eventType para 'read'
                    event.set('eventType', 'read')
    
                    // Registra o evento no client se o paywall não estiver na página
                    if (!Core.Status.get('paywall', 'displaying')) {
                        await EventsClient.register(event.toJSON())
                        console.info('Events', 'READ', 'disparado em: ' + moment().format('mm:ss'))
                    }
                }
            })
            
        } catch (ex) {
            console.error('tracker' + ex)
            console.warn('tracker Error send read event')
        }
    }

    async sendEvent (eventType, evNote) {
        try {
            if (!evNote) {
                throw new Error('EvNote não enviado')
            }

            if (Core.Status.get('tracker', 'status' !== 'finished')) {
                setTimeout(_.bind(this.sendEvent, this, eventType, evNote), 500)
                return
            }

            // Faz um clone do evento principal
            const event = new Event(this.Event.toJSON())
            event.set('eventType', eventType.toLowerCase())
            event.set('eventNotes', evNote)

            await EventsClient.register(event.toJSON())
            console.info('Events', eventType, `disparado em: ${moment().format('mm:ss')}`)
        } catch (ex) {
            console.error(ex)
        }
    }

    setEvent () {
        try {
            this.Event.set('article', (Core.Content.get('article')) ? Core.Content.get('article') : '')
            this.Event.set('city', (Core.User.get('city')) ? Core.User.get('city') : '')
            this.Event.set('contentId', (Core.Content.get('guid')) ? Core.Content.get('guid') : '')
            this.Event.set('country', (Core.User.get('country')) ? Core.User.get('country') : '')
            this.Event.set('hasClub', (Core.User.get('hasClub')) ? Core.User.get('hasClub') : '')
            this.Event.set('jwt', (Core.User.get('JWT')) ? Core.User.get('JWT') : '')
            this.Event.set('latitude', (Core.User.get('latitude')) ? Core.User.get('latitude') : '')
            this.Event.set('longitude', (Core.User.get('longitude')) ? Core.User.get('longitude') : '')
            this.Event.set('referrer', (Core.Content.get('referrer')) ? Core.Content.get('referrer') : document.referrer)
            this.Event.set('region', (Core.User.get('region')) ? Core.User.get('region') : '')
            this.Event.set('resolution', (Core.User.get('resolution')) ? Core.User.get('resolution') : '')
            this.Event.set('sdk', (Core.Content.get('tagVersion')) ? `Assembler2 - v${Core.Content.get('tagVersion')}` : 'Assembler - Error')
            this.Event.set('section', (Core.Content.get('sectionComplete')) ? Core.Content.get('sectionComplete') : '')
            this.Event.set('sessionId', (Core.User.get('sessionId')) ? Core.User.get('sessionId') : '')
            this.Event.set('site', (Core.Content.get('site')) ? Core.Content.get('site') : '')
            this.Event.set('statsCategories', (Core.Content.get('tags')) ? Core.Content.get('tags') : '')
            this.Event.set('statsPushedAt', (Core.Content.get('pushedAt')) ? Core.Content.get('pushedAt') : '')
            this.Event.set('statsStartDecayAfterDays', (Core.Content.get('startDecayAfterDays')) ? Core.Content.get('startDecayAfterDays') : '')
            this.Event.set('statsWeight', (Core.Content.get('weight')) ? Core.Content.get('weight') : '')
            this.Event.set('subscriptionId', (Core.User.get('ValidSubscriptionId')) ? Core.User.get('ValidSubscriptionId') : '')
            this.Event.set('thumbUrl', (Core.Content.get('thumbUrl')) ? Core.Content.get('thumbUrl') : '')
            this.Event.set('ticketId', (Core.User.get('ticketId')) ? Core.User.get('ticketId') : '')
            this.Event.set('timezone', (Core.User.get('timezone')) ? Core.User.get('timezone') : '')
            this.Event.set('title', (Core.Content.get('title')) ? Core.Content.get('title') : '')
            this.Event.set('url', window.location.href)
            this.Event.set('urlPage', (Core.Content.get('finalURL')) ? Core.Content.get('finalURL') : window.location.href)
            this.Event.set('userId', (Core.User.get('id')) ? Core.User.get('id') : '')
            this.Event.set('verbs', (Core.Content.get('verbs')) ? Core.Content.get('verbs') : '')
            this.Event.set('userAgent', (navigator.userAgent) ? navigator.userAgent : '')

            return this
        } catch (ex) {
            console.info(ex)
            // console.warn('Error setEvent tracker')

            // Força a finalização do processo
            Core.Status.update('tracker', 'status', 'finished')
        }
    }

    _setReadTime (time) {
        // Valor padrão
        this.readTime = 10000

        // Caso breves
        if(Core.Content.get('isNote')) {
            this.readTime = 5000
        }
    }
}
