/* eslint-disable no-undef */
/**
 * @alias Core
 */
export default window[CONSTANTS.coreName]

const Alert = window[CONSTANTS.coreName].Alert
const Config = window[CONSTANTS.coreName].Config
const Status = window[CONSTANTS.coreName].Status
const Tasks = window[CONSTANTS.coreName].Tasks
const Events = window[CONSTANTS.coreName].Events
const Lazyloading = window[CONSTANTS.coreName].Lazyloading
const Visibility = window[CONSTANTS.coreName].Visibility
const Message = window[CONSTANTS.coreName].Message

export { Alert, Config, Status, Tasks, Events, Lazyloading, Visibility, Message }
