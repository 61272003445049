import HTTPRequest from '_abstracts/libs/http-request'
import { Config } from '_abstracts/core'

export default class EventsClient {
    static async register (data) {
        try {
            return await HTTPRequest.request({
                url: `${Config.get('services').get('events')}/register/`,
                data,
                method: 'POST'
            })
        } catch (ex) {
            console.error(ex)
            throw ex
        }
    }

    // static registerSempreFamiliaCookie (data) {
    //     Axios.post(config.services.exchangerCookie, data, {
    //         withCredentials: true
    //     })
    // }
}
