import Backbone from 'backbone'
import Core from '_abstracts/core'
import $ from 'jquery'

export default class ElementView extends Backbone.View {
    initialize () {
        this.repeat = false

        this.on(Core.Events.SHOW_ELEMENT, this.sendEvent, this)

        Core.Lazyloading.addView(this)
    }

    sendEvent () {
        Core.Tasks.add(['tracker'], () => {
            const EvNote = $(this.$el).data('event-notes')
            Core.Tracker.sendEvent('view', EvNote)
        })
    }
}
