import Axios from 'axios'
import qs from 'qs'

export default class HTTPRequest {
    
    static async request (options) {
        try {
            if (!options.url) {
                throw new Error('URL not sent')
            }

            // Gera o objeto request básico
            const request = {
                url: options.url,
                method: (options.method) ? options.method : 'GET'
            }

            // Adiciona o header do formato json
            if (options.contentType === 'json' ||
                typeof options.contentType === 'undefined') {
                // console.log('adiciona application/json')
                request.headers = {
                    'content-type': 'application/json'
                }
            }

            // Adiciona o header do formato form-data
            if (options.contentType === 'form-data') {
                request.headers = {
                    'content-type': 'application/x-www-form-urlencoded'
                }
            }

            // Trata o Post
            if ((request.method === 'POST' || request.method === 'PUT')  && options.data) {
                request.data = (options.contentType === 'form-data')
                    ? qs.stringify(options.data) : options.data
                // request.data = options.data
            }

            // Trata o Get
            if (request.method === 'GET' && options.data) {
                request.params = options.data
            }

            // Adiciona os demais headers
            if (options.headers) {
                if (typeof request.headers === 'undefined') request.headers = {}

                Object.assign(request.headers, options.headers)
            }

            if (!options.responseType !== 'none') {
                request.responseType = 'json'
            }

            if (options.withCredentials) {
                request.withCredentials = true
            }

            // Define o timeout padrão
            
            request.timeout = (options.timeout) ? options.timeout : 4600

            // Define o tratamento da resposta
            request.transformResponse = [(data) => {
                // Do whatever you want to transform the data
                // sobrescreve retorno response.Data.data
                return data
            }]

            const response = await Axios.request(request)

            if (!response.data) {
                throw new Error('Response Fail')
            }

            return response.data
        } catch (ex) {
            console.error(ex)

            let msgError = ex.toString()
            if (options.url) {
                msgError = `${options.url} - ${msgError.replace('Error: ', '')}`
            }

            throw new Error(msgError)
        }
    }
}
