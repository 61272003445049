import Backbone from 'backbone'
import _ from 'underscore'
import ElementView from './element-view'

export default class ElementsListView extends Backbone.View {
    initialize () {
        this.setElement("[data-tracker-view='true']")

        _.each(this.$el, (obj) => {
            // tslint:disable-next-line:no-unused-expression
            new ElementView({
                el: obj
            })
        })
    }
}
