import { each } from 'jquery'
import Core from '_abstracts/core'

export default class ThirdStatistics {
    constructor () {
        Core.Status.update('statistics', 'status', 'finished')

        Core.Tasks.add(Core.Events.CHANGE_CONTENT, () => {
            this.sendVirtualPage()
        })
    }

    /**
     *  Envia a página virtual
     *  a cada matéria na rolagem infinita de Breves
     */
    sendVirtualPage () {
        this.sendViewToAnalytics()

    //     this.sendViewToChartBeat()

        this.sendViewToCxense()

    }

    /**
     * Envia um evento de view para o CXense
     *
     */
    sendViewToCxense (referrer) {
        try {
            window.cX = window.cX || {}
            window.cX.callQueue = window.cX.callQueue || []
            window.cX.callQueue.push(['initializePage'])
            window.cX.callQueue.push(['setSiteId', '1141875083272162426'])
            window.cX.callQueue.push(['sendPageViewEvent',
                {
                    location: Core.Config.get('siteURL') + Core.Content.get('URL'),
                    referrer
                }
            ])

        } catch (ex) {
            console.error(ex)
        }
    }

    /**
     * Envia um evento de view para o Analytics
     *
     */
    sendViewToAnalytics () {
        try {
            if (window.gtag) {
                window.gtag('config', 'UA-23088598-1', {
                    page_location: Core.Config.get('siteURL') + Core.Content.get('URL'),
                    page_path: this.model.get('url'),
                    page_title: this.model.get('title')
                })
                // gtag("event", "page_view", { send_to: "UA-23088598-1" });
            } else {
                console.info('gtag não encontrado')
            }
        } catch (ex) {
            console.error(ex)
        }
    }

    /**
     * Envia um evento de view para o Chartbeat
     *
     */
    // sendViewToChartBeat () {
    //     try {

    //         // config.sections:
    //         var taxonomies = Core.Content.get('taxonomies')
    //         var sections = ""

    //         if (typeof taxonomies !== "undefined" && typeof taxonomies.section !== "undefined" && typeof taxonomies.section.primary !== "undefined")
    //             if (typeof taxonomies.section.primary.name !== "undefined" && taxonomies.section.primary.name != null && taxonomies.section.primary.name != "")
    //                 sections = taxonomies.section.primary.name
    //             else if (typeof taxonomies.section.primary.slug !== "undefined" && taxonomies.section.primary.slug != null && taxonomies.section.primary.slug != "")
    //                 sections = taxonomies.section.primary.slug
    //         else if (typeof Core.Content.get('article') !== "undefined" && Core.Content.get('article') != null && Core.Content.get('article') != "")
    //             sections = Core.Content.get('article')

    //         // config.authors:
    //         var authors = Core.Content.get('authors')
    //         var authorsName = ""

    //         if (typeof Core.Content.get('signatureName') !== "undefined" && Core.Content.get('signatureName') != null && Core.Content.get('signatureName') != "")
    //             authorsName = Core.Content.get('signatureName')
    //         else if (typeof Core.Content.get('signatureAgency') !== "undefined" && Core.Content.get('signatureAgency') != "" && Core.Content.get('signatureAgency') != null)
    //             authorsName = Core.Content.get('signatureAgency')
    //         else if (typeof authors !== "undefined" && typeof authors.writtenBy !== "undefined")
    //             authors.writtenBy.forEach(element => {
    //                 if (typeof element.displayName !== "undefined")
    //                     if(authorsName === "")
    //                         authorsName = element.displayName
    //                     else
    //                         authorsName = authorsName + ", " + element.displayName
    //             });

    //         // send to chartbeat
    //         pSUPERFLY.virtualPage({
    //             sections: sections,
    //             authors: authorsName,
    //             path: Core.Content.get('URL'),
    //             title: Core.Content.get('title')
    //         });

    //     } catch (ex) {
    //         console.info('chartbeat')
    //     }
    // }
}
