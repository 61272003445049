import Core from '_abstracts/core'
import $ from 'jquery'

Core.Tasks.add(['user'], () => {
    try {
        const cXenseIDs = {
            'gazetadopovo': '1138596144857280556',
            'semprefamilia': '1151002723869717418',
            'umdoisesportes': '1139722051567259179'
        }

        window.cX = window.cX || {};
        window.cX.callQueue = window.cX.callQueue || [];
        window.cX.options = cX.options || { compat: { c1x: { wait: 3000 }}};

        window.cX.CCE = cX.CCE || {};
        window.cX.CCE.callQueue = window.cX.CCE.callQueue || [];

        window.tp = window.tp || [];

        const identityTypes = { 'gdp_user-state': 'unlogged' }

        if (Core.User.get('isLogged')) {
            identityTypes['gdp_user-state'] = Core.User.get('isSubscriber') ? 'subscriber' : 'registered'
            window.cX.callQueue.push(['addExternalId', { 'id': Core.User.get('id'), 'type': 'gdp'}])
        }

        window.tp.push(['setCxenseId', cXenseIDs[Core.Config.get('domain')]]);
        window.cX.callQueue.push(['setSiteId', cXenseIDs[Core.Config.get('domain')]]);
        window.cX.callQueue.push(['setCustomParameters', identityTypes]);
        
        $('body').on('click', '.c-btn-share-post.open-comments, .open-comments.c-item-comments, .c-comments-box.open-comments', CXcomments);
        $('body').on('click', '.c-btn-share-post-footer.open-comments', CommentsBallon);
        $('body').on('click', '.c-btn-share-post.open-comments-lists', CommentsSection);

        window.cX.CCE.callQueue.push(['sendPageViewEvent']);
    } catch (ex) {
        console.error(ex)
    }
})

// Comentários - Matérias, Breves
function CXcomments(ev) {
    if (ev) {
        ev.preventDefault()
        ev.stopPropagation()
    }
    
    const screenType = Core.Page.screenType       
    
    window.cX.callQueue.push(['setCustomParameters', { 'gdp_comments-device': screenType }]);    
}

// Comentários - Matérias ( Black Ballon )
function CommentsBallon(ev) {
    if (ev) {
        ev.preventDefault()
        ev.stopPropagation()
    }
    
    const commentUrl = Core.Config.get('siteURL') + Core.Content.get('URL')    
    
    window.cX.callQueue.push(['setCustomParameters', { 'gdp_comments-ballon': commentUrl }]);
}

// Comentários - Editorias
function CommentsSection(ev) {
    if (ev) {
        ev.preventDefault()
        ev.stopPropagation()
    }

    const screenType = Core.Page.screenType
    const commentUrl = Core.Content.get('sectionPrimary')       
    
    window.cX.callQueue.push(['setCustomParameters', { 'gdp_comments-device': screenType, 'gdp_comments-section': commentUrl }]);
}

