import Core from '_abstracts/core'
import SalesForce from './salesforce'

// add antes de instanciar o obj SalesForce
Core.Tasks.add(['user', 'content', 'tracker'], 
    [
        () => {
            //console.log('salesforce', 'terminou user/content/tracker')
        },
        'https://100026685.collect.igodigital.com/collect.js', 
        () => {
            console.log("Instanciar SalesForce...")
            new SalesForce().process()
        }
    ]
)


