import Core from '_abstracts/core'

Core.Tasks.add(['user'], () => {
    try {
        const tags = []
    
        if (Core.User.get('isLogged')) {
            tags.push("logado")
    
            if(Core.User.get('isSubscriber')) {
                tags.push('assinante')
            } else {
                tags.push('nao-assinante')
            }
        } else {
            tags.push('nao-logado')
        }
    
        window.hj = window.hj || function() {
            (window.hj.q = window.hj.q || []).push(arguments)
        }
    
        window.hj('tagRecording', tags)
    } catch (ex) {
        console.error(ex)
    }
})
